import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "../components/pageTemplate";
import SEO from "../components/seo";
import Layout from "../components/layout";

export default function DefaultTemplate({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <PageTemplate title={frontmatter.title}>
      <SEO title={frontmatter.title} />
      <div className="content-container" dangerouslySetInnerHTML={{ __html: html }} />
    </PageTemplate>
  );

}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
  `;
